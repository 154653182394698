<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择影院组织" v-model:value="formState.organizationId" style="width: 190px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 190px;"
							@change="getCinemaId">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="触发预警手机号" name="warnPhone">
						<a-input placeholder="请输入手机号" v-model:value="formState.warnPhone"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="接收预警手机号" name="receivePhone">
						<a-input placeholder="请输入手机号" v-model:value="formState.receivePhone"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="预警订单号" name="orderNo">
						<a-input placeholder="请输入订单号" v-model:value="formState.orderNo"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="短信发送时间">
						<a-range-picker v-model:value="time"
							:showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }" />
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18"></a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list"
					:scroll="{ x: 1350 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'time-slot'">
							<div>{{ transDateTime(record.createTime) }}</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getOrganizationList,
		getCinemaList,
		getCinemaWarnRecord
	} from '@/service/modules/cinema.js';

	export default {
		components: {
			Icon,
		},
		data() {
			return {
				loading: false,
				showAll: false,
				searchData: {},
				formState: {
					// organizationId: 0,
					cinemaId: 0,
				},
				time: [],
				payTime: [],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				columns: [{
					title: '预警影院',
					dataIndex: 'cinemaName'
				}, {
					title: '触发预警手机号',
					dataIndex: 'warnPhone'
				}, {
					title: '触发预警订单号',
					dataIndex: 'orderNo',
				}, {
					title: '接收预警手机号',
					dataIndex: 'receivePhone',
				}, {
					title: '短信发送时间',
					key: 'time-slot',
				}],
				organizationList: [],
				cinemaAllList: [],
				total: {},
				refundVisible: false,
				refundData: {
					type: 1,
					refund: 0
				},
				detail: {},
				refundLoading: false,
			}
		},
		created() {
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				if (this.time && this.time.length) {
					this.searchData.startTime = this.moment(this.time[0]).unix();
					this.searchData.endTime = this.moment(this.time[1]).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.cinemaAllList = [];
				this.time = [];
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try {
					let {
						data
					} = await getCinemaWarnRecord(postData);
					setTimeout(() => {
						this.list = data.list;
						this.pagination.total = data.totalCount;
					}, 200);
					this.loading = false;
				} catch (error) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				// this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onRefund(item) {
				this.detail = item;
				this.refundData = {
					type: 1,
					refund: 0
				};
				this.refundVisible = true;
			},
			getCinemaId(value) {
				this.cinemaIds = value;
			},
			disabledDate(current) {
				return current && current >= this.moment().endOf('day');
			}
		}
	}
</script>

<style>
	.ui-refundForm__item {
		margin-bottom: 0px;
	}
</style>
<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}

	.ui-warn__box {
		margin: 10px 0 20px 0;
		padding: 10px 20px;
		background-color: antiquewhite;
		border-radius: 10px;
	}

	.ui-warn {
		color: #737272;
	}
</style>